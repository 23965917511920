<template>
  <ion-content class="prime-compliance-page">
    <div class="prime-compliance-page-container">
      <img class="mypcp-logo" src="../assets/logo.svg" />
      <h1>End User License Agreement</h1>
      <ion-row>
        <ion-col v-if="isLoading" class="text-center">
            <ion-spinner name="lines"></ion-spinner>
        </ion-col>
        <ion-col v-else-if="eulaContent">
          <div v-dompurify-html="eulaContent"></div>
        </ion-col>
        <ion-col v-else class="text-center">
          <ion-spinner name="lines"></ion-spinner>
        </ion-col>
      </ion-row>
      <footerCompliance></footerCompliance>
    </div>
  </ion-content>
</template>

<script>
import FooterCompliance from "@/components/Global/FooterCompliance";
import { engageFhirApi } from "@/services/EngageFhirApi";

export default {
  name: "EndUserLicenseAgreement",
  components: {
    FooterCompliance
  },
  data() {
    return {
      isLoading: true,
      eulaContent: '',
    };
  },
  created() {
    this.fetchEula();
  },
  methods: {
    async fetchEula() {
      try {
        const response = await engageFhirApi.get('/eula');
        this.eulaContent = response.message;
        this.isLoading = false;
      } catch (error) {
        khanSolo.error('Error fetching EULA', error);
      }
    }
  }
};
</script>

<style scoped>
.prime-compliance-page {
  background: var(--ion-background-color);
}
.prime-compliance-page-container {
  max-width: 740px;
  margin: 30px auto;
  padding: 0 20px;
}
.prime-compliance-page-container .mypcp-logo {
  width: 340px;
  display: block;
  margin: 30px auto;
}
.prime-compliance-page-container h1 {
  margin-bottom: 30px;
}
@media screen and (max-width: 540px) {
  .prime-compliance-page-container .mypcp-logo {
    width: 220px;
  }
}
</style>
